<div class="container py-5">
    <div class="product-container">
        <div class="product-imgs">
            <div class="imagenes__thumb-container">
                <img class="img_thumb selected"
                     *ngFor="let imagen of producto.imagenes"
                     [src]="imagen"
                     alt="imagen-producto"
                     (click)="img_seleccionada = imagen"
                >
            </div>
            <img class="img_seleccionada" [src]="img_seleccionada" alt="imagen-producto" >
        </div>
        <div class="product-details">
            <h2>{{ producto.titulo }}</h2>
            <div class="referencia my-3">
                <p class="text-center">Ref: {{ producto.referencia }}</p>
            </div>
            <div class="product-descript">
                <p>{{ producto.descripcion }}</p>
            </div>
        </div>
    </div>

    <div class="product-share">
        <h5>Compartir: </h5>
        <div class="icon-container">
            <a target="_blank" [href]="enlace_whatsapp">
                <fa-icon class="icono-whatsapp" [icon]="icono_whatsapp"/>
            </a>
        </div>
    </div>

    <div class="contact-form mt-5">
        <form id="formulario-contacto" (ngSubmit)="onSubmit()">
            <div class="grid-superior">
                <app-input
                        [control]="form.controls.nombre"
                        [label]="'Nombre *'"
                        [placeholder]="'Juan Perez'"
                        [type]="'text'"
                        [id]="'nombre'"
                />
                <app-input
                        [control]="form.controls.email"
                        [placeholder]="'miemail@email.com'"
                        [label]="'Email *'"
                        [type]="'text'"
                        [id]="'email'"
                />
                <app-input
                        [control]="form.controls.telefono"
                        [label]="'Teléfono *'"
                        [placeholder]="'+34 654 98 73 21'"
                        [type]="'tel'"
                        [id]="'telefono'"
                />
                <app-input
                        [control]="form.controls.asunto"
                        [placeholder]="'Información sobre precios'"
                        [label]="'Asunto *'"
                        [type]="'text'"
                        [id]="'asunto'"
                />
            </div>
            <app-text-editor
                    [control]="form.controls.mensaje"
                    [placeholder]="'Tu mensaje'"
                    [id]="'mensaje'"
            />
            <div class="custom-control custom-checkbox mt-5 mb-3">
                <input
                        required
                        type="checkbox"
                        id="leido"
                        name="leido"
                        class="custom-control-input ng-untouched ng-pristine ng-valid"
                        [formControl]="form.controls.politicas"
                >
                <label for="leido" class="custom-control-label">
            <span class="ms-2">
              He leído y acepto la <a [routerLink]="'/politica'"><strong>Política de privacidad</strong></a>
            </span>
                </label>
            </div>
            <div class="button-container">
                <button type="submit" class="btn-message" [disabled]="!form.valid">
                    <fa-icon [icon]="icono_enviar" class="btn-icon"/>
                    Enviar mensaje
                </button>
            </div>
        </form>
    </div>

</div>
