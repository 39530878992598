<app-page-container-publico [title] = "'Quienes Somos'">
  <app-breadcrumb-container 
    [nombre_imagen]="'fondoempresa.jpg'"
  />
  <div class="container quienes-somos__container">
    <div class="video-container">
      <video class="video" controls="">
        <source *ngIf="idioma === 'es-ES' || idioma == '' || idioma == 'es'" src="../../../../assets/videocorporativo_es.mp4" />
        <source *ngIf="idioma === 'en-EN'" src="../../../../assets/videocorporativo_en.mp4" />
        <source *ngIf="idioma === 'fr-FR'" src="../../../../assets/videocorporativo_fr.mp4" />
      </video>
    </div>
    <div class="text-container" *ngIf="idioma === 'es-ES' || idioma == 'es' || idioma == ''">
      <h2>Antigüedades Reinaldos</h2>
      <p>
        Somos una empresa familiar con más de 30 años de experiencia en el sector de antigüedades y rústicos.
      </p>
      <p>
        Disponemos de una exposición con más de 30.000 m2 donde encontrarás articulos únicos y exclusivos que forman parte de la historia. 
      </p>
      <p>
        Nos distingue el trato cercano con el cliente y asesoramiento en cada proyecto, hacemos entregas a cualquier lugar con transportes especializados en tratar nuestros materiales con especial cuidado.
      </p>
      <p>
        Pidenos presupuesto sin compromiso, visitanos y no te arrepentirás. 
      </p>
    </div>
    <div class="text-container" *ngIf="idioma === 'en-EN'">
      <h2>Antigüedades Reinaldos</h2>
      <p>
        We are a family-owned business with over 30 years of experience in the antiques and rustic furniture sector.
      </p>
      <p>
        We have a showroom of over 30,000 m² where you will find unique and exclusive items that are part of history.
      </p>
      <p>
        What sets us apart is our close relationship with our customers and our advice on every project. We deliver anywhere with specialized transportation that handles our materials with special care.
      </p>
      <p>
        Request a quote with no obligation, visit us, and you won't regret it.
      </p>
    </div>
    <div class="text-container" *ngIf="idioma === 'fr-FR'">
      <h2>Antigüedades Reinaldos</h2>
      <p>
        Nous sommes une entreprise familiale avec plus de 30 ans d'expérience dans le secteur des antiquités et du mobilier rustique.
      </p>
      <p>
        Nous disposons d'une exposition de plus de 30 000 m² où vous trouverez des articles uniques et exclusifs qui font partie de l'histoire.
      </p>
      <p>
        Ce qui nous distingue, c'est notre relation de proximité avec nos clients et notre accompagnement dans chaque projet. Nous effectuons des livraisons partout, avec des transports spécialisés qui prennent un soin particulier de nos matériaux.
      </p>
      <p>
        Demandez-nous un devis sans engagement, venez nous rendre visite et vous ne le regretterez pas.
      </p>
    </div>
  </div>

</app-page-container-publico>