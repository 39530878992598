import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { ArticuloImagenModel } from 'src/app/models/entity/articulo_imagen.model';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ArticuloServices } from 'src/app/services/https/articulos.services';
import { ArticulosImagenesServices } from 'src/app/services/https/articulos_imagenes.services';
import { FamiliaServices } from 'src/app/services/https/familia.services';
import { SubfamiliaServices } from 'src/app/services/https/subfamilia.services';
import { ZonasService } from 'src/app/services/https/zonas.services';
import {SeoService} from "../../../services/seo/seo.service";

@Component({
  selector: 'app-intranet-articulos-page',
  templateUrl: './intranet-articulos-page.component.html',
  styleUrls: ['./intranet-articulos-page.component.scss'],
  providers: [ArticuloServices, ArticulosImagenesServices, FamiliaServices, SubfamiliaServices, ZonasService]
})
export class IntranetArticulosPageComponent {
  es_listado: Boolean;
  total_articulos: number = 0;
  articulo: ArticuloModel | null = null;
  zonas: ZonasModel[] = [];
  familias: FamiliaModel[] = [];
  subfamilias: SubfamiliaModel[] = [];
  id: number = 0;
  data: ArticuloModel[] = [];
  imagenes_articulos: ArticuloImagenModel[] = [];
  registros: number = 100;
  pagina_actual: number = 1;
  total_paginas: number = 0;
  filtro: string = '';
  cargando: boolean = true;

  constructor(
    private _router: Router,
    private _articulo_service: ArticuloServices,
    private _articulo_imagenes_service: ArticulosImagenesServices,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal,
    private _subfamilia_service: SubfamiliaServices,
    private _familia_service: FamiliaServices,
    private _zonas_service: ZonasService,
    private _seo: SeoService
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 3;
    if (!this.es_listado)
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      }
  }

  async ngOnInit(): Promise<void> {
    this._seo.setIndexFollow(false);
    if (this.es_listado) {
      await this.obtener_listado();
    } else {
      this.cargando = true;
      try {
        this._loader.notifyLoadChange(true);
        this.familias = await this.obtener_familias();
        this.zonas = await this.obtener_zonas();
        if (this.id !== 0) {
          this.articulo = await this._articulo_service.ficha(this.id);
          const { familias } = await this._familia_service.paginacion(1, 100, '');
          const { subfamilias } = await this._subfamilia_service.paginacion(1, 100, `WHERE familia_uid = ${this.articulo.familia_uid}`)
          const { zonas } = await this._zonas_service.lista_completa();
          this.familias = familias;
          this.subfamilias = subfamilias;
          this.zonas = zonas;
          this.imagenes_articulos = await this._articulo_imagenes_service.ficha_articulo(this.id);
        }
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false);
        this.cargando = false
      }
    }
  }

  async obtener_listado() {
    try {
      this._loader.notifyLoadChange(true);
      this.cargando = true;
      this.total_articulos = await this._articulo_service.total_articulos()
      const { articulos, pagina_actual, total_paginas } = await this._articulo_service.paginacion(this.pagina_actual, this.registros, this.filtro);
      this.data = articulos;
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch (e: any) {
      this.data = [];
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      this.cargando = false;
    }
  }

  async recibe_filtro(filtro_recibido: any) {
    const { filtro_texto } = filtro_recibido
    this.filtro = `WHERE (INSTR(articulo, '${filtro_texto}') > 0) OR (INSTR(proveedor, '${filtro_texto}') > 0) OR (INSTR(descripcion, '${filtro_texto}') > 0) OR (INSTR(referencia, '${filtro_texto}') > 0)`;
    await this.obtener_listado();
  }

  recibe_pagina(pagina_recibida: number) {
    this.pagina_actual = pagina_recibida;
    this.ngOnInit();
  }

  abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "articulo"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "articulos"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          this._articulo_service.elimina(id);
        } catch (e: any) {
          this._toast.error(e);
          this._loader.notifyLoadChange(false);
        } finally {
          this.ngOnInit();
        }
      }
    });
  }

  async obtener_familias(): Promise<FamiliaModel[]> {
    try {
      const { familias } = await this._familia_service.paginacion(1, 100, '');
      return familias
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async obtener_subfamilias(familia_id: number) {
    try {
      this._loader.notifyLoadChange(true);
      const { subfamilias } = await this._subfamilia_service.paginacion(1, 100, `where familia_uid = ${familia_id}`);
      this.subfamilias = subfamilias;
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async obtener_zonas(): Promise<ZonasModel[]> {
    try {
      const { zonas } = await this._zonas_service.lista_completa();
      return zonas
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async recibe_nuevo_articulo(
    datos: {
      zona_uid: number,
      articulo: string,
      articulo_en: string,
      articulo_fr: string,
      datos_proveedor: string,
      descripcion: string,
      descripcion_en: string,
      descripcion_fr: string,
      familia_uid: number,
      subfamilia_uid: number,
      file_name: string,
      stock: number,
      form: File,
      referencia: string,
      precio_compra: string,
      precio_1: string,
      precio_2: string,
      keywords: string
    }
  ) {
    this._loader.notifyLoadChange(true);
    try {
      const  { 
        zona_uid, articulo, articulo_en, articulo_fr,
        datos_proveedor, descripcion, descripcion_en, descripcion_fr,
        familia_uid, subfamilia_uid, file_name, stock, form, referencia,
        precio_compra, precio_1, precio_2, keywords
      } = datos
    
    const { uid } = await this._articulo_service.inserta_articulo(
      zona_uid, articulo, articulo_en, articulo_fr,
      datos_proveedor, descripcion, descripcion_en, descripcion_fr,
      familia_uid, subfamilia_uid, file_name, stock, form, referencia, precio_compra, precio_1, precio_2, keywords
    )
    this._router.navigate(['intranet/articulos'])
    this._toast.success("Articulo creado con éxito");
    this.ngOnInit();
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false)
    }
  }

  async recibe_actualiza_articulo(
    datos: {
      zona_uid: number,
      articulo: string,
      articulo_en: string,
      articulo_fr: string,
      datos_proveedor: string,
      descripcion: string,
      descripcion_en: string,
      descripcion_fr: string,
      familia_uid: number,
      subfamilia_uid: number,
      file_name: string,
      stock: number,
      form: File | undefined,
      referencia: string,
      precio_compra: string,
      precio_1: string,
      precio_2: string,
      keywords: string
    }
  ) {
    this._loader.notifyLoadChange(true);
    try {
      const  { 
        zona_uid, articulo, articulo_en, articulo_fr,
        datos_proveedor, descripcion, descripcion_en, descripcion_fr,
        familia_uid, subfamilia_uid, file_name, stock, form, referencia,
        precio_compra, precio_1, precio_2, keywords
      } = datos
    
    await this._articulo_service.actualiza_articulo(
      this.id, zona_uid, articulo, articulo_en, articulo_fr,
      datos_proveedor, descripcion, descripcion_en, descripcion_fr,
      familia_uid, subfamilia_uid, file_name, stock, referencia,
      precio_compra, precio_1, precio_2, keywords, form
    )
    this._toast.success("Articulo creado con éxito");
    // this._router.navigate(['intranet/articulos'])
    // window.location.reload();
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false)
    }
  }
}
