import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from '../helpers/session.helper';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private _storage: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._storage.obtainToken(); // Obtén el token de tu función

    // Verificar si la solicitud es de tipo DELETE
    if (req.method === 'DELETE') {
      // Agregar token solo si es una solicitud DELETE
      if (token && token !== 'TokenInvalido') {
        const modifiedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(modifiedReq);
      } else {
        console.log('Token inválido o no disponible para la solicitud DELETE:', req.url);
        // Redirigir si el token es inválido o ausente
        this.router.navigate(['/']).then(() => {});
        return next.handle(req);
      }
    } else if (req.method === 'GET') {
      if (
        req.url.includes('familias') ||
        req.url.includes('novedades') ||
        req.url.includes('sliders') ||
        req.url.includes('articulos') ||
        req.url.includes('articulos_imagen') ||
        req.url.includes('configuracion')
      ) {
          // Clonar la solicitud sin modificarla y continuar
          return next.handle(req);
      }
    }

    // Verificar si es una solicitud que no requiere autenticación
    if (
      req.url.includes('usuarios/login') ||
      req.url.includes('usuarios/registro') ||
      req.url.includes('usuarios/existeEmail') ||
      req.url.includes('usuarios/comprueba_token')
    ) {
      // Clonar la solicitud sin modificarla y continuar
      return next.handle(req);
    }

    // Para cualquier otra solicitud que no sea DELETE, agregar el token si es válido
    if (token && token !== 'TokenInvalido') {
      const modifiedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
      return next.handle(modifiedReq);
    }

    console.log('Token inválido o no disponible:', req.url, req.method);
    // Redirigir si el token es inválido o ausente para otras solicitudes
    // this.router.navigate(['/']);
    return next.handle(req);
  }
}