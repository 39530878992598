import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom,  } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IActualizaNovedad, IInsertaNovedad, INovedad } from 'src/app/models/interface/novedad.interface';
import { StorageService } from '../helpers/session.helper';

@Injectable()
export class NovedadServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router, private _storage: StorageService) { }

  async ficha(id: number): Promise<NovedadModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}novedades/${id}`, httpOptions));
      return new NovedadModel(
        data.uid, data.idioma, data.descripcion, data.novedad, data.keywords, data.file_name
      )
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async lista(filtro: string): Promise<NovedadModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}novedades?filtro=${filtro}`, httpOptions));
      const novedades: NovedadModel[] = [];
      data.forEach((item: any) => {
        novedades.push(new NovedadModel(
          item.uid, item.idioma, item.descripcion, item.novedad, encodeURI(item.keywords), item.file_name
        ))
      })
      return novedades;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async lista_intranet(filtro: string): Promise<NovedadModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}novedades?filtro=${filtro}`, httpOptions));
      const novedades: NovedadModel[] = [];
      data.forEach((item: any) => {
        novedades.push(new NovedadModel(
          item.uid, item.idioma, item.descripcion, item.novedad, item.keywords, item.file_name
        ))
      })
      return novedades;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async paginacion(pagina: number, registros: number, filtro: string): Promise<{ novedades: NovedadModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}articulos?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const lista_novedades: NovedadModel[] = [];
      res.data.articulos.forEach((item: INovedad) => {
        lista_novedades.push(
          new NovedadModel(
            item.uid,
            item.idioma,
            item.descripcion, 
            item.novedad,
            item.keywords,
            item.file_name
        ));
      });
      const { pagina_actual, total_paginas } = res.data;
      return { novedades: lista_novedades, pagina_actual, total_paginas };
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  async inserta(datos: IInsertaNovedad) {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('idioma', datos.idioma);
    formData.append('novedad', datos.novedad);
    formData.append('descripcion', datos.descripcion);
    formData.append('keywords', datos.keywords);
    formData.append('file_name', datos.file_name);
    formData.append('file_type', datos.imagen.type);
    formData.append('file_size', datos.imagen.size.toString());
    formData.append('imagen', datos.imagen);
    formData.append('usuario_alta', globalState.identity?.uid.toString() || '0');
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}novedades`, formData, httpOptions));
      return  new NovedadModel(
        data.uid,
        data.idioma,
        data.descripcion,
        data.novedad,
        data.keywords,
        data.file_name
      );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar un artículo';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza(id: number, datos: IActualizaNovedad) {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('uid', id.toString());
    formData.append('idioma', datos.idioma);
    formData.append('novedad', datos.novedad);
    formData.append('descripcion', datos.descripcion);
    formData.append('keywords', datos.keywords);
    formData.append('file_name', datos.file_name);
    if (datos.imagen !== undefined) {
      formData.append('file_type', datos.imagen.type);
      formData.append('file_size', datos.imagen.size.toString());
      formData.append('imagen', datos.imagen);
    }
    formData.append('usuario_alta', globalState.identity?.uid.toString() || '0');
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}novedades/actualiza`, formData, httpOptions));
      return  new NovedadModel(
        data.uid,
        data.idioma,
        data.descripcion,
        data.novedad,
        data.keywords,
        data.file_name
      );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar un artículo';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id_articulo: number): Promise<Boolean> {
    const token = this._storage.obtainToken();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }).set('Authorization', 'Bearer ' + token),
    };
    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}novedades/${id_articulo}`, httpOptions));
      return true;
    } catch (e: any) {
      return false;
    }
  }
}
