import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faWhatsapp, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ProductosTipoUnoModel } from 'src/app/models/entity/productos_tipo_uno_model';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { globalState, urlBase } from 'src/global';
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoadService} from "../../../services/helpers/load.service";
import {MensajeServices} from "../../../services/https/mensaje.services";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-producto-content-tipo-uno',
  templateUrl: './producto-content-tipo-uno.component.html',
  styleUrls: ['./producto-content-tipo-uno.component.scss'],
  providers: [MensajeServices]
})
export class ProductoContentTipoUnoComponent {
  @Input() producto!: ProductosTipoUnoModel;
  @Input() ver_boton_compartir: boolean = false;
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  img_seleccionada: string = "";
  url: string;
  mensaje_whatsapp: string = "";
  enlace_whatsapp: string = "";
  icono_whatsapp: IconDefinition = faWhatsapp

  form = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    telefono: new FormControl<string>('', [Validators.required]),
    asunto: new FormControl<string>('', [Validators.required]),
    mensaje: new FormControl<string>('', [Validators.required]),
    politicas: new FormControl<boolean>(false, [Validators.required]),
  })


  constructor(
      private _router: Router,
      private _loader: LoadService,
      private _mensaje_service: MensajeServices,
      private _toast: ToastrService
  ) {
    this.url = _router.url;
  }

  ngOnInit() {
    this.form.patchValue({
      asunto: `${this.producto.referencia} / ${this.producto.titulo}`,
      mensaje: `Me gustaría obtener información sobre ${this.producto.titulo}`
    })
    this.img_seleccionada = this.producto.imagenes[0];
    this.mensaje_whatsapp = `¡Hola! \n\n He encontrado este/a: ${this.producto.titulo} en: ${urlBase}${this.url}`;
    this.enlace_whatsapp = `https://wa.me/?text=${encodeURIComponent(this.mensaje_whatsapp)}`;
  }



  protected readonly icono_enviar = faPaperPlane;

  async onSubmit(): Promise<void> {
    const { nombre, email, telefono, asunto, mensaje, politicas } = this.form.value!!;
    try {
      this._loader.notifyLoadChange(true);
      const datos = await this._mensaje_service.envia_mensaje(nombre!!, email!!, telefono!!, asunto!!, mensaje!!, politicas!!)
      if (datos) {
        this._toast.success("¡Mensaje enviado!")
      }
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
