import {Component, Inject, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/https/auth.services';
import { globalState } from 'src/global';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-intranet-container',
  templateUrl: './intranet-container.component.html',
  styleUrls: ['./intranet-container.component.scss'],
  providers: [AuthService]
})
export class IntranetContainerComponent {

  constructor (
    private _router: Router,
    private _auth_service: AuthService,
    @Inject(PLATFORM_ID) private platformId: object
) {

  }

  async ngOnInit(): Promise<void> {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const token_valido = await this._auth_service.comprueba_token()
        if (!token_valido) {
          // deleteToken();
          // this._router.navigate(['/'])
        }
      }

    } catch (e: any) {
      if (e === "Token caducado") {
        localStorage.removeItem(globalState.tokenName);
        sessionStorage.removeItem(globalState.tokenName)
        globalState.token = undefined;
        this._router.navigate(["/"]);
      }
    }
  }
}

