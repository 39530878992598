<div *ngIf="total_paginas <= 8" class="paginacion mt-1">
    <button
            *ngFor="let pagina of [].constructor(total_paginas); let i = index"
            [ngClass]="{ 'selected': pagina_actual == i + 1, 'btn-navegacion': true }"
            (click)="ir_a_pagina(i + 1)"
    >
        {{ i + 1 }}
    </button>
</div>

<div *ngIf="total_paginas > 8" class="paginacion mt-1">
    <!-- Botones de navegación inicial -->
    <button
            [ngClass]="{ 'btn-navegacion': true }"
            (click)="ir_a_pagina(1)"
            *ngIf="pagina_actual != 1">
        <<
    </button>

    <button
            [ngClass]="{ 'btn-navegacion': true }"
            (click)="ir_a_pagina(pagina_actual - 1)"
            *ngIf="pagina_actual != 1">
        <
    </button>

    <!-- Primera página -->
    <button
            [ngClass]="{ 'selected': pagina_actual == 1, 'btn-navegacion': true }"
            (click)="ir_a_pagina(1)">
        1
    </button>

    <!-- Segunda página (solo si la actual no es 1) -->
    <button
            *ngIf="total_paginas > 1"
            [ngClass]="{ 'selected': pagina_actual == 2, 'btn-navegacion': true }"
            (click)="ir_a_pagina(2)">
        2
    </button>

    <!-- "..." si la página actual está más allá de la 3 -->
    <span *ngIf="pagina_actual > 3"> ... </span>

    <!-- Página actual si está en medio -->
    <button
            *ngIf="pagina_actual > 2 && pagina_actual < total_paginas - 1"
            [ngClass]="{ 'selected': true, 'btn-navegacion': true }"
            (click)="ir_a_pagina(pagina_actual)">
        {{ pagina_actual }}
    </button>

    <!-- "..." si la página actual no está en las últimas 2 -->
    <span *ngIf="pagina_actual < total_paginas - 2"> ... </span>

    <!-- Penúltima página -->
    <button
            [ngClass]="{ 'selected': pagina_actual == total_paginas - 1, 'btn-navegacion': true }"
            (click)="ir_a_pagina(total_paginas - 1)">
        {{ total_paginas - 1 }}
    </button>

    <!-- Última página -->
    <button
            [ngClass]="{ 'selected': pagina_actual == total_paginas, 'btn-navegacion': true }"
            (click)="ir_a_pagina(total_paginas)">
        {{ total_paginas }}
    </button>

    <!-- Botones de navegación final -->
    <button
            [ngClass]="{ 'btn-navegacion': true }"
            (click)="ir_a_pagina_siguiente()"
            *ngIf="pagina_actual != total_paginas">
        >
    </button>

    <button
            [ngClass]="{ 'btn-navegacion': true }"
            (click)="ir_a_pagina(total_paginas)"
            *ngIf="pagina_actual != total_paginas">
        >>
    </button>
</div>
