import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { UsuarioModel } from "src/app/models/entity/usuario.model";
import { globalState } from "src/global";
import { isPlatformBrowser } from "@angular/common";

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  obtainToken(): string {
    if (!isPlatformBrowser(this.platformId)) return "TokenInvalido";
    return localStorage.getItem(globalState.tokenName) ?? "TokenInvalido";
  }

  obtainVersion(): number {
    if (!isPlatformBrowser(this.platformId)) return 0;
    return Number(localStorage.getItem("versionCode")) || 0;
  }

  saveToken(token: string): boolean {
    if (!isPlatformBrowser(this.platformId)) return false;
    localStorage.setItem(globalState.tokenName, token);
    globalState.token = token;
    return true;
  }

  saveVersion(version: number): boolean {
    if (!isPlatformBrowser(this.platformId)) return false;
    localStorage.setItem("versionCode", version.toString());
    return true;
  }

  saveUser(response: UsuarioModel): boolean {
    globalState.identity = response;
    return true;
  }

  deleteToken(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(globalState.tokenName);
      window.location.replace(globalState.frontUrl);
    }
  }
}
