import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCalendar, faEnvelope, faMap, faPaperPlane, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { CardIconoTextoEnlaceModel } from 'src/app/models/entity/card_icono_texto_enlace_tipo_uno.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { MensajeServices } from 'src/app/services/https/mensaje.services';
import { globalState } from 'src/global';
import {SeoService} from "../../../services/seo/seo.service";

@Component({
  selector: 'app-contacto-page',
  templateUrl: './contacto-page.component.html',
  styleUrls: ['./contacto-page.component.scss'],
  providers: [MensajeServices]
})
export class ContactoPageComponent {
  idioma: string = globalState.idioma_usuario;
  tarjetas_contacto: CardIconoTextoEnlaceModel[] = [];
  icono_enviar: IconDefinition = faPaperPlane
  telefono_whatsapp: string = "";

  form = new FormGroup({
    nombre: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    telefono: new FormControl<string>('', [Validators.required]),
    asunto: new FormControl<string>('', [Validators.required]),
    mensaje: new FormControl<string>('', [Validators.required]),
    politicas: new FormControl<boolean>(false, [Validators.required]),
  })

  constructor(
    private _mensaje_service: MensajeServices,
    private _toast: ToastrService,
    private _loader: LoadService,
    private _seo: SeoService
  ) {


  }
  async onSubmit(): Promise<void> {
    this._seo.title.setTitle('Contacto | Compra y Venta de Antigüedades y Rústicos en Murcia | Antigüedades Diego Reinaldos')
    this._seo.setCanonicalUrl("diegoreinaldos.com/contacto");
    this._seo.setIndexFollow(true);
    const { nombre, email, telefono, asunto, mensaje, politicas } = this.form.value!!;
    try {
      this._loader.notifyLoadChange(true);
      const datos = await this._mensaje_service.envia_mensaje(nombre!!, email!!, telefono!!, asunto!!, mensaje!!, politicas!!)
      if (datos) {
        this._toast.success("¡Mensaje enviado!")
      }
    } catch (e: any) {
      this._toast.error(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  recibe_datos_cfg(event: any) {
    try {
      this._loader.notifyLoadChange(true);
      const { telefono, telefono_whatsapp } = event
      switch (this.idioma) {
        case "es-Es":
          let nuevo_horario = event.horario1
          if (event.horario2 !== '') {
            nuevo_horario = `${event.nuevo_horario} / ${event.horario2}`
          }
          this.tarjetas_contacto = [
            new CardIconoTextoEnlaceModel(
              "Email", faEnvelope, "mailto:info@diegoreinaldos.com", false, "info@diegoreinaldos.com"
            ),
            new CardIconoTextoEnlaceModel(
              "Dirección", faMap, "https://goo.gl/maps/DJ6civadDzRbP1667", true, "Autovía del Mediterraneo A7\n Salida 645\n Puerto Lumbreras (Murcia)"
            ),
            new CardIconoTextoEnlaceModel(
              "Horario", faCalendar, null, false, nuevo_horario
            ),
            new CardIconoTextoEnlaceModel(
              "Llámanos", faPhone, `"tel:${telefono}"`, false, `${telefono}/\n ${telefono_whatsapp } (Whatsapp)`
            ),
          ]
          break;
        case "fr-FR":
          let nuevo_horario_fr = event.horario1_fr
          if (event.horario2_fr !== '') {
            nuevo_horario = `${event.nuevo_horario_fr} / ${event.horario2_fr}`
          }
          this.tarjetas_contacto = [
            new CardIconoTextoEnlaceModel(
              "Email", faEnvelope, "mailto:info@diegoreinaldos.com", false, "info@diegoreinaldos.com"
            ),
            new CardIconoTextoEnlaceModel(
              "Adresse", faMap, "https://goo.gl/maps/DJ6civadDzRbP1667", true, "Autoroute Méditerranéenne A7\n Sortie 645\n Puerto Lumbreras (Murcie)"
            ),
            new CardIconoTextoEnlaceModel(
              "Horaires", faCalendar, null, false, nuevo_horario_fr
            ),
            new CardIconoTextoEnlaceModel(
              "Appelez-nous", faPhone, `"tel:${telefono}"`, false, `${telefono}/\n ${telefono_whatsapp } (Whatsapp)`
            ),
          ]
          break;
        case "en-EN":
          let nuevo_horario_en = event.horario1_en
          if (event.horario2_en !== '') {
            nuevo_horario = `${event.nuevo_horario} / ${event.horario2_en}`
          }
          this.tarjetas_contacto = [
            new CardIconoTextoEnlaceModel(
              "Email", faEnvelope, "mailto:info@diegoreinaldos.com", false, "info@diegoreinaldos.com"
            ),
            new CardIconoTextoEnlaceModel(
              "Address", faMap, "https://goo.gl/maps/DJ6civadDzRbP1667", true, "Mediterranean Highway A7\n Exit 645\n Puerto Lumbreras (Murcia)"
            ),
            new CardIconoTextoEnlaceModel(
              "Hours", faCalendar, null, false, nuevo_horario_en
            ),
            new CardIconoTextoEnlaceModel(
              "Call Us", faPhone, `"tel:${telefono}"`, false, `${telefono}/\n ${telefono_whatsapp } (Whatsapp)`
            ),
          ]

          break;

        default:
          let nuevo_horario_default = event.horario1
          console.log(nuevo_horario_default);
          if (event.horario2 !== '') {
            nuevo_horario_default = `${event.horario1} / ${event.horario2}`
          }
          this.tarjetas_contacto = [
            new CardIconoTextoEnlaceModel(
              "Email", faEnvelope, "mailto:info@diegoreinaldos.com", false, "info@diegoreinaldos.com"
            ),
            new CardIconoTextoEnlaceModel(
              "Dirección", faMap, "https://goo.gl/maps/DJ6civadDzRbP1667", true, "Autovía del Mediterraneo A7\n Salida 645\n Puerto Lumbreras (Murcia)"
            ),
            new CardIconoTextoEnlaceModel(
              "Horario", faCalendar, null, false, nuevo_horario_default
            ),
            new CardIconoTextoEnlaceModel(
              "Llámanos", faPhone, `"tel:${telefono}"`, false, `${telefono}/\n ${telefono_whatsapp } (Whatsapp)`
            ),
          ]
          break;
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
