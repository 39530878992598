import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { NovedadServices } from 'src/app/services/https/novedad.services';
import { SliderServices } from 'src/app/services/https/slider.services';
import { globalState } from 'src/global';
import { Title, Meta } from '@angular/platform-browser';
import {SeoService} from "../../../services/seo/seo.service";
import {ArticuloServices} from "../../../services/https/articulos.services";
import {ArticuloModel} from "../../../models/entity/articulos.model";

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss'],
  providers: [SliderServices, NovedadServices, ArticuloServices]
})
export class InicioPageComponent {
  idioma: string = globalState.idioma_usuario;
  sliders: ModelSlider[] = [];
  novedades: NovedadModel[] = [];
  articulos_tinajas: ArticuloModel[] = [];
  articulos_puertas: ArticuloModel[] = [];
  articulos_mesas: ArticuloModel[] = [];
  articulos_molino: ArticuloModel[] = [];
  articulos_fuentes: ArticuloModel[] = [];
  articulos_suelos: ArticuloModel[] = [];
  articulos_pilas: ArticuloModel[] = [];
  articulos_vigas: ArticuloModel[] = [];
  constructor(
    private _slider_services: SliderServices,
    private _novedad_services: NovedadServices,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _articulo_service: ArticuloServices,
    private _titleService: Title,
    private _metaService: Meta,
    private _seo: SeoService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    try {
      // Configurar meta etiquetas
      this._metaService.updateTag({ name: 'Cache-Control', content: 'no-cache, no-store, must-revalidate' });
      this._metaService.updateTag({ name: 'Pragma', content: 'no-cache' });
      this._metaService.updateTag({ name: 'Expires', content: '0' });
      this._loader.notifyLoadChange(true);
      this._seo.setCanonicalUrl("www.diegoreinaldos.com")
      this._seo.setIndexFollow(true);
      this._seo.title.setTitle("Compra y venta antigüedades arquitectónicas en Murcia. Muebles restaurados, materiales de derribo y objetos únicos con historia. Diego reinaldos");
      this.sliders = await this._slider_services.lista();
      const filtro = this.crea_filtro();
      // this.novedades = await this._novedad_services.lista(filtro);
      const idioma = globalState.idioma_usuario.split('-')[0];
      const tinajas = await this._articulo_service.paginacion(1, 5, `where referencia = 16139 or referencia = 15098 or referencia = 16131 and idioma = '${idioma}'`)
      const puertas = await this._articulo_service.paginacion(1, 5, `where referencia = 3014 or referencia = 5000 or referencia = 3024 and idioma = '${idioma}'`)
      // const hierros = await this._articulo_service.paginacion(1, 5, `where referencia = 00624 or referencia = 00409 or referencia = 00887 and idioma = '${idioma}'`)
      const mesas = await this._articulo_service.paginacion(1, 5, `where referencia = 2028 or referencia = 2144 or referencia = 10282 and idioma = '${idioma}'`)
      const piedras = await this._articulo_service.paginacion(1, 5, `where referencia = 9546 or referencia = 0257 or referencia = 9571 and idioma = '${idioma}'`)
      const fuentes = await this._articulo_service.paginacion(1, 5, `where referencia = 0595 or referencia = 0533 or referencia = 0583 and idioma = '${idioma}'`)
      const suelos =  await this._articulo_service.paginacion(1, 5, `where referencia = 2791 or referencia = 13903 or referencia = 13507 and idioma = '${idioma}'`)
      // const pnatural =  await this._articulo_service.paginacion(1, 5, `where referencia = 9445 or referencia = 13903 or referencia = 13507 and idioma = '${idioma}'`)
      const pilas =  await this._articulo_service.paginacion(1, 5, `where referencia = 8110 or referencia = 7503 or referencia = 8020 and idioma = '${idioma}'`)
      //const fregaderos = await this._articulo_service.paginacion(1, 5, `where referencia = 7968 or referencia = 7915 or referencia = 7035 and idioma = '${idioma}'`)
     // const decoraion = await this._articulo_service.paginacion(1, 5, `where referencia = 10551 or referencia = 10570 or referencia = 2172 and idioma = '${idioma}'`)
      const vigas = await this._articulo_service.paginacion(1, 3, `where referencia = 14009 or referencia = 14061 or referencia = 14018-1 and idioma = '${idioma}'`)
      this.articulos_pilas = pilas.articulos;
      this.articulos_tinajas = tinajas.articulos;
      this.articulos_puertas = puertas.articulos;
      this.articulos_mesas = mesas.articulos;
      this.articulos_molino = piedras.articulos;
      this.articulos_fuentes = fuentes.articulos;
      this.articulos_suelos = suelos.articulos;
      this.articulos_vigas = vigas.articulos;
    /*  this.articulos_piedra = pnatural.articulos;
      this.articulos_fregadero = fregaderos.articulos;
      this.articulos_decoracion = decoraion.articulos;
      this.articulos_hierro = hierros.articulos;
      */

    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }


  crea_filtro(): string {
    const idioma = globalState.idioma_usuario.split('-')[0];
    return `where idioma = '${idioma}'`
  }

  img_load(img_tinajas: HTMLImageElement) {
    console.log(`Ancho: ${img_tinajas.naturalWidth}, Alto: ${img_tinajas.naturalHeight}`);
  }
}
