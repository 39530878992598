import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { createSolicitudView } from '../helpers/model.helper';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IFamilia } from 'src/app/models/interface/familias.interface';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { IActualizaSubfamilia, IInsertaSubfamilia, ISubfamilias } from 'src/app/models/interface/subfamilia.interface';
import { StorageService } from '../helpers/session.helper';

@Injectable()
export class SubfamiliaServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router, private _storage: StorageService) { }
  
  async paginacion(pagina: number, registros: number, filtro: string): Promise<{ subfamilias: SubfamiliaModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}subfamilias?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const lista_familias: SubfamiliaModel[] = [];
      res.data.subfamilias.forEach((item: ISubfamilias) => {
        lista_familias.push(
          new SubfamiliaModel(
            item
        ));
      });
      const { pagina_actual, total_paginas } = res.data;
      return { subfamilias: lista_familias, pagina_actual, total_paginas };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  async ficha(id_subfamilia: number): Promise<SubfamiliaModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}subfamilias/${id_subfamilia}`, httpOptions));
      return new SubfamiliaModel(
       data
      )
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async inserta(datos: IInsertaSubfamilia): Promise<SubfamiliaModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const { subfamilia_enlace, subfamilia, subfamilia_en, subfamilia_fr, familia_uid, descripcion_en, descripcion_fr, descripcion, archivo} = datos
    const formData = new FormData();
    formData.append('subfamilia_enlace', subfamilia_enlace);
    formData.append('subfamilia', subfamilia);
    formData.append('subfamilia_en', subfamilia_en);
    formData.append('subfamilia_fr', subfamilia_fr);
    formData.append('familia_uid', familia_uid.toString());
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('descripcion', descripcion);
    formData.append('imagen',archivo);
    formData.append('file_name', archivo.name);
    formData.append('file_type', archivo.type);
    formData.append('file_size', archivo.size.toString());
    formData.append('usuario_alta',globalState.identity?.uid.toString() || '0');
    formData.append('idioma', 'es');

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}subfamilias`, formData, httpOptions));
      const { data } = res 
      return new SubfamiliaModel(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }
  
  async obtiene_stock_subfamilia(id_subfamilia: number): Promise<number> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
   
    const json = {
      id_subfamilia: id_subfamilia.toString()
    }

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}subfamilias/stock`, json, httpOptions));
      const { data } = res 
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza(uid:number, datos: IActualizaSubfamilia): Promise<SubfamiliaModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const { subfamilia_enlace, subfamilia, subfamilia_en, subfamilia_fr, familia_uid, descripcion_en, descripcion_fr, descripcion, archivo} = datos
    const formData = new FormData();
    formData.append('uid', uid.toString());
    formData.append('subfamilia_enlace', subfamilia_enlace);
    formData.append('subfamilia', subfamilia);
    formData.append('subfamilia_en', subfamilia_en);
    formData.append('subfamilia_fr', subfamilia_fr);
    formData.append('familia_uid', familia_uid.toString());
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('descripcion', descripcion);
    formData.append('usuario_alta',globalState.identity?.uid.toString() || '0');
    formData.append('idioma', 'es');
    if (archivo !== undefined) {
      formData.append('imagen',archivo);
      formData.append('file_name', archivo.name);
      formData.append('file_type', archivo.type);
      formData.append('file_size', archivo.size.toString());
    }

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}subfamilias/actualiza`, formData, httpOptions));
      const { data } = res 
      return new SubfamiliaModel(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id_articulo: number): Promise<Boolean> {
    const token = this._storage.obtainToken();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }).set('Authorization', 'Bearer ' + token),
    };
    try {
      const res = await firstValueFrom(this._http.delete<any>(`${this.url}subfamilias/${id_articulo}`, httpOptions));
      return true;
    } catch (e: any) {
      console.log(e);
      return false;
    }
  }
}
