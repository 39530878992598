<app-loading-component></app-loading-component>
<header id="header" >
  <div *ngIf="!cargando">
    <app-admin-header *ngIf="iniciada_sesion"/>
    <app-header-container [horario1]="horario1" [horario2]="horario2" [telefono]="telefono" [telefono_whatsapp]="telefono_whatsapp"/>
  </div>
</header>
<main class="">
  <ng-content ></ng-content>
</main>

<footer class="text-white" *ngIf="!cargando">
  <div class="footer__up container">
    <div class="three-cols">
      <div>
        <p class="footer_tl">Contacto</p>
        <ul class="contact-us-link">
          <li>
            <fa-icon [icon]="icono_mapa" class="me-3"/>
            <a rel="nofollow" href="https://maps.app.goo.gl/DpiuARV1wu31e4maA" target="_blank" class="footer-letra">Autovía del Mediterraneo A7 Salida 645 <br> (30890) Puerto Lumbreras - Murcia</a>
          </li>
          <li>
            <fa-icon [icon]="icono_telefono" class="me-3"/>
            <a href="tel:{{ telefono }}" class="footer-letra">{{ telefono }}</a>
          </li>
          <li class="icono-whatsapp">
            <fa-icon [icon]="icono_whatsapp" class="me-3"/>
            <a href="tel:+34676174545" class="footer-letra">{{ telefono_whatsapp }}</a>
          </li>
          <li>
            <fa-icon [icon]="icono_email" class="me-3"/>
            <a href="mailto:info@diegoreinaldos.com" class="footer-letra">info@diegoreinaldos.com</a>
          </li>
        </ul>
      </div>
      <div>
        <p class="footer_tl">Información</p>
        <ul class="contact-us-link">
          <li>
            <a [routerLink]="'aviso'" target="_blank" class="footer-letra">Aviso Legal</a>
          </li>
          <li>
            <a [routerLink]="'politica'" target="_blank" class="footer-letra">Política de Privacidad</a>
          </li>
          <li class="icono-whatsapp">
            <a [routerLink]="'cookies'" target="_blank" class="footer-letra">Cookies</a>
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
          <a *ngIf="usuario_sesion === undefined" class="login-button" [routerLink]="'/login'">Inicia Sesión</a>
          <a *ngIf="usuario_sesion !== undefined" class="login-button" [routerLink]="'/login'">{{ usuario_sesion.nombre }}</a>

      </div>
    </div>
  </div>
  <div class="footer__down">
    <i class="bx bx-map"></i>
  </div>
</footer>

<app-cookie-banner />