import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, firstValueFrom, throwError} from 'rxjs';
import {loginData} from 'src/app/models/interface/login.interface';
import {globalState} from 'src/global';
import {UsuarioModel} from 'src/app/models/entity/usuario.model';
import {createUsuario} from '../helpers/model.helper';
import {StorageService} from '../helpers/session.helper';
import {platformBrowser} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class AuthService {
    public url: string = globalState.url;
    public identity: UsuarioModel | undefined = globalState.identity;
    public token: undefined | string = globalState.token;

    constructor(@Inject(PLATFORM_ID) private platformId: object, private _http: HttpClient, private _router: Router, private _storage: StorageService) {
    }

    // Realiza el login del usuario
    async login(email: string, password: string): Promise<{ token: string, usuario: UsuarioModel }> {
        const params = new HttpParams()
            .set('email', email)
            .set('password', password);

        const httpOptions = {
            headers: new HttpHeaders({Accept: 'application/json'}),
            params: params,
        };
        try {
            const res = await firstValueFrom(this._http.post<any>(this.url + 'usuarios/login', params, httpOptions));
            const token = res.data.token;
            const usuario = createUsuario(res.data.usuario);
            return {token, usuario}
        } catch (e: any) {
            const errorMessage = e?.error?.message || 'Error al iniciar sesión';
            return Promise.reject(errorMessage);

        }
    }

    async comprueba_token(): Promise<Boolean> {
        const token = this._storage.obtainToken();
        const params = new HttpParams();
        if (token === "TokenInvalido") {
            return false;
        }
        const httpOptions = {
            headers: new HttpHeaders({Accept: 'application/json'}).set('Authorization', 'Bearer ' + token)
        };
        try {
            const res = await firstValueFrom(this._http.post<any>(this.url + 'usuarios/comprueba_token', params, httpOptions));
            return true
        } catch (e: any) {
            const errorMessage = e?.error?.message || 'Error al iniciar sesión';
            return Promise.reject(errorMessage);
        }
    }


    getIdentity(): UsuarioModel | undefined {
        // Se comprueba identity
        if (globalState.identity) {
            this.identity = globalState.identity
            // Se comprueba token
            if (globalState.token) {
                this.token = globalState.token;
            }
        } else {
            let localToken: string | null = null;
            if (isPlatformBrowser(this.platformId)) {
                localToken = localStorage.getItem(globalState.tokenName);
            }
            if (localToken) {
                try {
                    const newIdentity: UsuarioModel = JSON.parse(atob(localToken));
                    if (newIdentity != null) {
                        globalState.identity = new UsuarioModel(
                            newIdentity.uid,
                            newIdentity.nivel,
                            newIdentity.idioma,
                            newIdentity.email,
                            newIdentity.password,
                            newIdentity.nombre,
                            newIdentity.telefono,
                            newIdentity.descripcion,
                            newIdentity.usuario_alta,
                            newIdentity.usuario_consulta,
                            newIdentity.usuario_modificacion,
                            newIdentity.consultas,
                            newIdentity.modificaciones,
                            newIdentity.logged,
                            newIdentity.fecha_acceso,
                            newIdentity.clave,
                            newIdentity.fecha_alta,
                            newIdentity.fecha_consulta,
                            newIdentity.fecha_modificacion
                        );
                        this.identity = globalState.identity;
                    } else {
                        this.identity = undefined;
                    }
                } catch (e: any) {
                    this.identity = undefined;
                }
            }
        }
        return this.identity;
    }
}
