import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';

@Injectable()
export class MensajeServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }
  

  async envia_mensaje(
    nombre: string,
    email: string,
    telefono: string,
    asunto: string,
    mensaje: string,
    politicas: boolean
  ): Promise<boolean>
  {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const params = {
      nombre: nombre,
      email: email,
      telefono: telefono,
      asunto: asunto,
      mensaje: mensaje,
      politicas: politicas ? 1 : 0
    };

    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}mensajes`, params, httpOptions));
      return true
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al enviar el mensaje';
      return Promise.reject(errorMessage);
    }
  }
}
