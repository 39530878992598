import { Component } from '@angular/core';
import { globalState } from 'src/global';
import { Title, Meta } from '@angular/platform-browser';
import {SeoService} from "../../../services/seo/seo.service";


@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent {
  idioma: string = globalState.idioma_usuario

  constructor(private _seo: SeoService) {
  }

  ngOnInit(): void {
    this._seo.title.setTitle('Quienes Somos | Compra y Venta de Antigüedades y Rústicos en Murcia | Antigüedades Diego Reinaldos')
    this._seo.setCanonicalUrl("diegoreinaldos.com/contacto");
  }
}
