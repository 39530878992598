import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { globalState } from 'src/global';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { StorageService } from './services/helpers/session.helper';
import { AuthService } from './services/https/auth.services';
import { Router } from '@angular/router';
import {isPlatformBrowser} from "@angular/common";
import { IdiomaService } from './services/seo/cookie.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ConfiguracionService,
    AuthService,
    StorageService
  ]
})
export class AppComponent {
  title = 'Diego Reinaldos - Antigüedades';
  constructor(
    private _auth_service: AuthService,
    private _router: Router,
    private _storage: StorageService,
    private _idioma_service: IdiomaService,
    @Inject(PLATFORM_ID) private platformId: object
  ){
    globalState.identity = this._auth_service.getIdentity();
  }

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Comprobamos idioma y asignamos a una variable global y/o al token
      globalState.idioma_usuario = this._idioma_service.getIdioma();
     

      // Comprueba el token
      if (this._storage.obtainToken() !== "") {
        try {
          const valido = await this._auth_service.comprueba_token();
          if (valido) {
            globalState.sesion_iniciada = true;
          }
        } catch(e) {
          if (e === "Token caducado") {
            localStorage.removeItem(globalState.tokenName);
            sessionStorage.removeItem(globalState.tokenName)
            globalState.token = undefined;
            await this._router.navigate(["/"]);
          }
        }
      }
    }
  }
}
