import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {
  private cookieName = 'idioma_seleccionado';
  private token_cokie_name = 'diego_reinaldos_token'

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  /** Obtiene el idioma de la cookie, si no existe devuelve 'es' */
  getIdioma(): string {
    return this.cookieService.get(this.cookieName) || 'es';
  }

  /** Guarda el idioma en una cookie */
  setIdioma(idioma: string) {
    this.cookieService.set(this.cookieName, idioma, 365, '/'); // Expira en 1 año
    if (isPlatformBrowser(this.platformId)) {
      location.reload(); // Opcional, si es necesario recargar la página
    }
  }

  getToken() {
    return this.cookieService.get(this.token_cokie_name) || '';
  }

  setCookie(cookieName: string, token: string) {
    this.cookieService.set(this.cookieName, token, 7, '/');
  }
}
