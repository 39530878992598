<app-page-container-publico
        [title] = "'Inicio'"
>
    <section class="pt-0 pb-5">
        <app-slider [sliders]="sliders" />
    </section>

    <section class="pt-0 pb-5">
        <div class="container novedades">
            <section>
                <div class="inicio-title-container">
                    <h3>Puertas y Ventanas Antiguas y nuevas de Madera y Hierro Forjado</h3>
                    <a class="desktop-btn title-section" routerLink="/catalogo/puertas-ventanas">Ver más</a>
                </div>
                <p class="mb-5">Encuentra ventanas rústicas y puertas de época con detalles originales en madera y forja.</p>
                <div class="product-container three-cols">
                    <ng-container *ngFor="let novedad of articulos_tinajas">
                        <div class="novedad-container">
                            <a class="novedad-container__title d-flex w-100" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                <img loading="lazy" class="img-fluid mx-auto" style="max-height: 250px; object-fit: cover;width: auto" [ngSrc]="novedad.file_name" [alt]="novedad.keywords" [width]="232" [height]="250">
                            </a>
                            <div class="novedad-content">
                                <h3 class="cursor">
                                    <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                        <b>
                                            {{ novedad.articulo }}
                                        </b>
                                    </a>
                                </h3>
                                <p class="novedad-container__desc">
                                    {{ novedad.descripcion }}
                                </p>
                            </div>
                            <div class="btn-ver__container">
                                <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                    Ver
                                </a>
                            </div>
                        </div>
                    </ng-container>

                </div>
                <a class="mobile-btn title-section" routerLink="/catalogo-puertas/ventanas">Ver más</a>
            </section>
            <section>
                <div class="inicio-title-container">
                    <h3>Tinajas de Barro y Cerámica: Piezas Tradicionales para Decoración</h3>
                    <a class="desktop-btn title-section" routerLink="/catalogo/tinajas">Ver más</a>
                </div>
                <p>Tinajas antiguas recuperadas, ideales para jardines, patios y decoración rústica.</p>
                <div class="product-container three-cols">
                    <ng-container *ngFor="let novedad of articulos_puertas">
                        <div class="novedad-container">
                            <a class="novedad-container__title d-flex w-100" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                <img loading="lazy" class="img-fluid mx-auto" style="max-height: 250px; object-fit: cover;width: auto" [ngSrc]="novedad.file_name" [alt]="novedad.keywords" [width]="232" [height]="250">
                            </a>
                            <div class="novedad-content">
                                <h3 class="cursor">
                                    <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                        <b>
                                            {{ novedad.articulo }}
                                        </b>
                                    </a>
                                </h3>
                                <p class="novedad-container__desc">
                                    {{ novedad.descripcion }}
                                </p>
                            </div>
                            <div class="btn-ver__container">
                                <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                    Ver
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <a class="mobile-btn title-section" routerLink="/catalogo/hierro-y-forja">Ver más</a>
            </section>
            <section>
                <div class="inicio-title-container">
                    <h3>Vigas de Madera Antigua: Estructuras con Carácter para Techos y Paredes</h3>
                    <a class="desktop-btn title-section" routerLink="/catalogo/vigas-de-madera">Ver más</a>
                </div>
                <p>Vigas recuperadas de construcciones antiguas, ideales para proyectos rústicos.</p>
                <div class="product-container three-cols">
                    <ng-container *ngFor="let novedad of articulos_vigas">
                        <div class="novedad-container">
                            <a class="novedad-container__title d-flex w-100" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                <img loading="lazy" class="img-fluid mx-auto" style="max-height: 250px; object-fit: cover;width: auto" [ngSrc]="novedad.file_name" [alt]="novedad.keywords" [width]="232" [height]="250">
                            </a>
                            <div class="novedad-content">
                                <h3 class="cursor">
                                    <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                        <b>
                                            {{ novedad.articulo }}
                                        </b>
                                    </a>
                                </h3>
                                <p class="novedad-container__desc">
                                    {{ novedad.descripcion }}
                                </p>
                            </div>
                            <div class="btn-ver__container">
                                <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                    Ver
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <a class="mobile-btn title-section" routerLink="/catalogo/hierro-y-forja">Ver más</a>
            </section>
            <section>
                <div class="inicio-title-container">
                    <h3>Mesas, Bancos y Sillas nuevas y antiguas de Madera Maciza</h3>
                    <a class="desktop-btn title-section" routerLink="/catalogo/mesas-bancos-y-sillas">Ver más</a>
                </div>
                <p>Muebles restaurados con acabados únicos que aportan autenticidad y calidez.</p>
                <div class="product-container three-cols">
                    <ng-container *ngFor="let novedad of articulos_mesas">
                        <div class="novedad-container">
                            <a class="novedad-container__title d-flex w-100" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                <img loading="lazy" class="img-fluid mx-auto" style="max-height: 250px; object-fit: cover;width: auto" [ngSrc]="novedad.file_name" [alt]="novedad.keywords" [width]="'232'" [height]="'250'">
                            </a>
                            <div class="novedad-content">
                                <h3 class="cursor">
                                    <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                        <b>
                                            {{ novedad.articulo }}
                                        </b>
                                    </a>
                                </h3>
                                <p class="novedad-container__desc">
                                    {{ novedad.descripcion }}
                                </p>
                            </div>
                            <div class="btn-ver__container">
                                <a class="novedad-container__title" [routerLink]="'/catalogo/'+novedad.familia_enlace+'/'+ novedad.subfamilia_enlace+'/'+novedad.uid">
                                    Ver
                                </a>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <a class="mobile-btn title-section" routerLink="/catalogo/mesas-bancos-y-sillas">Ver más</a>
            </section>
        </div>
    </section>
</app-page-container-publico>
